/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {
  addBreadcrumb,
  configureScope,
  captureException,
  Breadcrumb,
  Event,
} from '@sentry/browser';
import { environment } from '@environments/environment';

export const SENTRY_IGNORE_ERRORS = [
  // Disable Webpack Chunk Loading Errors (there is little we can do to fix these)
  'Loading chunk',
  // Disable errors due to Facebook (when the popup is closed without login)
  'The person is not logged into this app or we are unable to tell',
  // Disable error related to user using `google translate`
  'a[b].target.className.indexOf is not a function',
  // TODO: Unknown error (sending too many events - no breakage for the user)
  't is not a function',
  // Login Errors (avoid reporting)
  'USER_NOT_FOUND',
  // Geocoder Errors (avoid reporting - too many)
  'INVALID_GEOCODE_DATA',
  // Session expired (will renew gratefully)
  '[ UNKNOWN ] HTTP 401 Unauthorized',
  // Listing no-longer exists (but likely still indexed by google)
  '[ UNKNOWN ] HTTP 404 Not Found',
  '[ UNKNOWN ] HTTP 404 Not Found (Status: 404)',
  '[ LISTING_NOT_FOUND ]',
  'LISTING_NOT_FOUND',
  'LISTING_IN_DELIVERY',
  'Status: 404',
  // 'USER_BANNED',
  'USER_SESSION_EXPIRED',
  'USER_LOGIN_INVALID_CREDENTIALS',
  // Language that does not exist requested (and returned the HTML App instead of the JSON)
  '[ SyntaxError: Unexpected token < in JSON at position 0 ] undefined (Status: 200)',
  // Weird Safari error (no effect on website, no readable / traceable log)
  'TypeError: Type error',
  // Weird error that we get 0 http status for icons
  'Error retrieving icon',
  '0 Unknown Error',
  'Non-Error exception captured with keys',
  // Loggi error
  'DELIVERY_ORDER_LOGGI_INVALID_ESTIMATE_PRICE_SERVER_REQUEST',
  // Pre-loading Dialog Errors
  'ERROR_LOADING_DIALOG',
  // Deleted Account errors
  'ACCOUNT_DELETED',
  'USER_BANNED',
  'BANNED (Status: 403)',
  // Firebase Errors
  'QuotaExceededError',
  'IDBDatabase',
  'Connection is closing',
  'Database deleted by request of the user',
  'installations/app-offline',
  'offline',
  'www.gstatic.com',
  // Web worker passing messages error
  'DataCloneError',
  // Google Maps
  'Could not load "util"',
  // Usupported Browser / Spider
  `Can't find variable: Promise`,
  `'Promise' is undefined`,
  `Promise is not defined`,
  `Intl`,
  `MutationObserver is not defined`,
  `NS_ERROR_NOT_INITIALIZED`,
  `In this configuration Angular requires Zone.js`,
  'Failed to load Google Maps API',
  'instantSearchSDKJSBridgeClearHighlight',
  // Tabby not available message (not an error)
  'Tabby not available',
  // SSR Is Down Error or Restarting
  '<!DOCTYPE html>',
  // Anonymous user Address after login
  'Entity not found or user is not the owner.',
  'Wrong phone number',
  // Google Pay
  'User closed the Payment Request UI',
  // Quara Finance
  'Loan at Quara Finance previously cancelled by customer',
  // GTM
  'Illegal invocation',
  // locked cart
  `Cart can't be updated: locked cart`,
  'TABBY_PAYMENT_REJECTED',
  'INVALID_PRICE',
];

export class SentryUtil {
  public static setMSClarityTag(
    key: string,
    value?: string | Array<string> | null
  ): void {
    if (!environment.inServer) {
      try {
        if ((window as any)?.clarity) {
          (window as any).clarity('set', key, value);
        }
      } catch (err) {
        /** SSR Not Supported */
      }
    }
  }

  public static shouldReportToSentry(): boolean {
    return Boolean(environment.reportToSentry);
  }

  public static reportException(
    err: any,
    reThrow: boolean,
    analyticsReport?: (parsed: string) => void
  ) {
    if (!err) {
      return;
    }

    if (typeof err === 'string') {
      // If the Error comes from the Location user permissions ignore
      if (
        err === 'PERMISSION_DENIED' ||
        err === 'POSITION_UNAVAILABLE' ||
        err === 'TIMEOUT' ||
        err === 'UNKNOWN'
      ) {
        return;
      }

      // If the error is a string, convert into valid error
      const parsedError = new Error(err);
      popsyReportToSentry(parsedError);
      if (analyticsReport) {
        analyticsReport(err);
      }
    } else if (err.error && err.error.error) {
      // Ignore Expired Sessions (these are expected and treated on Application Level)
      if (err.error.error === 'USER_SESSION_EXPIRED') {
        SentryUtil.addObjectKeysToScope('session', {
          expired: 'YES',
        });
        SentryUtil.addBreadcrumb({
          category: 'auth',
          message: err.error.message || 'Session Expired',
          level: 'warning',
          type: 'session',
        });
        return;
      }

      if (err.message) {
        SentryUtil.addBreadcrumb({
          category: 'error',
          message: err.message,
          level: 'error',
          type: err.name || `${err.status}` || 'http',
        });
      }

      if (err.error.message) {
        SentryUtil.addBreadcrumb({
          category: 'error',
          message: err.error.message,
          level: 'error',
          type: err.error.error || err.error.code || 'http',
        });
      }

      SentryUtil.addObjectKeysToScope(`error`, err.error);

      const parsedError = new Error(
        `[ ${err.error.error} ] ${err.error.message} (Status: ${err.status})`
      );
      popsyReportToSentry(parsedError);
      if (analyticsReport) {
        analyticsReport(parsedError.message);
      }
    } else if (typeof ProgressEvent !== 'undefined' && err instanceof ProgressEvent) {
      // Don't send progress events to Sentry (Status 0 and no error message)
      if (environment.debugAnalitycs) {
        SentryUtil.printException(err);
      }
    } else if (err.error) {
      let errorMessage = `[ ${err.error} ] ${err.message}`;

      if (typeof err.error !== 'string') {
        if (err.error.length > 0) {
          const errors = err.error;
          // HTTP Response has an array with multiple errors
          // Log the count of errors
          configureScope((scope) => {
            scope.setExtra(`error_count`, `${errors.length}`);
          });

          // Log the messages of all the errors in the Sentry context (for readability)
          for (let i = 0; i < errors.length; i += 1) {
            const currentError = errors[i];
            if (currentError) {
              SentryUtil.addBreadcrumb({
                category: 'error',
                message: currentError.message || currentError.error || '?',
                level: 'error',
                type: currentError.error || 'unknown',
              });
              SentryUtil.addObjectKeysToScope(`error[${i}]`, currentError);
            }
          }

          const errData = err.error[0];
          errorMessage = `[ ${errData.error} ] ${errData.message}`;
        } else if (err.error.message) {
          SentryUtil.addBreadcrumb({
            category: 'error',
            message: err.error.message || err.error.code || '?',
            level: 'error',
            type: err.error.error || err.error.code || err.error.name || 'unknown',
          });

          // HTTP Response contains only one error
          SentryUtil.addObjectKeysToScope(`error`, err.error);
          errorMessage = `${err.error.message}`;
        }
      } else {
        SentryUtil.addBreadcrumb({
          category: 'error',
          message: err.message || err.error || '?',
          level: 'error',
          type: err.error || err.code || err.name || 'unknown',
        });
        SentryUtil.addObjectKeysToScope(`error`, err);
      }

      if (err.status || err.status === 0) {
        errorMessage = `${errorMessage} (Status: ${err.status})`;
      } else if (err.name) {
        errorMessage = `${errorMessage} (${err.name})`;
      }
      if (err.status || err.status === 0) {
        errorMessage = `${errorMessage} --> (URL: ${err.url})`;
      }

      const parsedError = new Error(errorMessage);
      SentryUtil.reportToSentry(parsedError);
      if (analyticsReport) {
        analyticsReport(errorMessage);
      }
    } else {
      SentryUtil.addBreadcrumb({
        category: 'error',
        message: err.message || err.name || '?',
        level: 'error',
        type: 'js',
      });

      SentryUtil.addObjectKeysToScope(`error`, err);

      // Handle Client Error (Angular Error, ReferenceError...)
      SentryUtil.reportToSentry(err);
      if (analyticsReport) {
        analyticsReport(err.message || err.name || '?');
      }
    }

    if (reThrow) {
      throw err;
    }
  }

  /**
   * @deprecated Use popsyReportToSentry instead
   */
  public static reportToSentry(error: Error): void {
    return popsyReportToSentry(error);
  }

  public static addObjectKeysToScope(name: string, obj: any): void {
    if (obj) {
      let hasMessage = false;
      for (const key of Object.keys(obj)) {
        configureScope((scope) => {
          if (key === 'message') {
            hasMessage = true;
          }

          const value = obj[key];
          scope.setExtra(`${name}:${key}`, `${SentryUtil.objToString(value)}`);
        });
      }

      if (!hasMessage && obj.message) {
        configureScope((scope) => {
          scope.setExtra(`${name}:message`, `${SentryUtil.objToString(obj.message)}`);
        });
      }
    }
  }

  public static objToString(obj: any): string {
    let text = '';
    if (obj) {
      if (typeof obj === 'string') {
        text = obj;
      } else {
        try {
          text = JSON.stringify(obj);
        } catch (error) {
          const data = new Array(0);
          data.push(`--- CIRCULAR JSON ---\n`);
          for (const key of Object.keys(obj)) {
            const value = obj[key as any];
            if (typeof value === 'string') {
              data.push(`"${key}": "${value}"`);
            } else {
              data.push(`"${key}": "[ object ]"`);
            }
          }
          text = data.join('\n');
        }
      }
    }
    return text;
  }

  public static addBreadcrumb(breadcrumb: Breadcrumb): void {
    if (SentryUtil.shouldReportToSentry()) {
      if (environment.inServer) {
        console.log(
          JSON.stringify({
            severity: `${breadcrumb?.level || 'DEBUG'}`.toUpperCase(),
            labels: {
              type: breadcrumb?.type || undefined,
              level: breadcrumb?.level || undefined,
              event_id: breadcrumb?.event_id || undefined,
              category: breadcrumb?.category || undefined,
              message: breadcrumb?.message || undefined,
              data: breadcrumb?.data || undefined,
              timestamp: breadcrumb?.timestamp || undefined,
            },
            jsonPayload: breadcrumb?.data || undefined,
          })
        );
      } else {
        addBreadcrumb(breadcrumb);
      }
    } else if (environment.debugAnalitycs) {
      SentryUtil.printBreadcrumb(breadcrumb);
    }
  }

  public static printException(err: unknown): void {
    console.log(`\tSentry Catched Error`);
    console.log(`-------------------------------------[ START ]`);
    console.log(err);
    console.log(`-------------------------------------[ END ]`);
  }

  public static printBreadcrumb(breadcrumb: Breadcrumb): void {
    console.log(`\tSentry Breadcrumb`);
    console.log(`-------------------------------------[ START ]`);
    console.log(`\tCategory: "${breadcrumb.category || ''}"`);
    console.log(`\tMessage: "${breadcrumb.message || ''}"`);
    console.log(`\tLevel: "${breadcrumb.level || ''}"`);
    console.log(`\tType: "${breadcrumb.type || ''}"`);
    console.log(`-------------------------------------[ END ]`);
  }

  public static filteredError = (event: Event | undefined): any | null => {
    if (event) {
      const eventValue = event?.exception?.values?.length
        ? event?.exception?.values[0]?.value
        : '';
      const eventFrames = event?.exception?.values?.length
        ? event?.exception?.values[0]?.stacktrace?.frames
        : [];
      const eventType = event?.exception?.values?.length
        ? event?.exception?.values[0]?.type
        : '';
      const eventBreadcrumbs = event?.breadcrumbs?.length ? event?.breadcrumbs : [];
      // Filter out errors that we don't want to report
      // if GET ORDER 400 on track order page
      if (
        (eventValue?.includes('Order not found') &&
          (event.transaction === 'delivery_details' ||
            event.transaction === 'search-delivery')) ||
        eventValue?.includes('Java object is gone')
      ) {
        return null;
      }
      // Filter errrors from old delivery flow
      if (event.transaction === 'delivery_create_overview') {
        return null;
      }
      if (eventType === 'TypeError' && event.transaction === 'delivery_details') {
        return null;
      }

      // Tamara Widget Errors
      if (
        eventType === 'AxiosError' &&
        eventBreadcrumbs?.some((breadcrumb) =>
          breadcrumb.message?.includes('TamaraWidgetV2 has an Error')
        )
      ) {
        return null;
      }
      if (
        eventType === 'TypeError' &&
        eventFrames?.some(
          (frame) =>
            frame.filename?.includes('/inshop/launcher-v2.js') ||
            frame.filename?.includes('/inShop/push-notifications.js') || // omnisend
            frame.filename?.includes('clarity.js') ||
            frame.filename?.includes('<anonymous>') ||
            frame.filename?.includes('popsy.accounts.google') ||
            frame.filename?.includes('gtm.js') || // Google Tag Manager
            frame.filename?.includes('/tasheel-checkout.js') || // Baseeta
            frame.filename?.includes('forms/main.js') || // "https://omnisnippet1.com/forms/main.js.map
            frame.filename?.includes('gp/p/js/pay.js') || // Google Pay
            frame.filename?.includes('tag/uet/') // UET
        )
      ) {
        // Filter TypeErrors from libraries (clarity.js...)
        return null;
      }
      // Filter out errors on Tidio chat
      if (eventType === 'SecurityError' && eventValue?.includes('Blocked a frame')) {
        return null;
      }
      // Filter out errors from Clarity
      if (
        eventValue?.includes(
          'Uncaught (in promise): RangeError: Maximum call stack size'
        ) &&
        eventValue?.includes('clarity.js')
      ) {
        return null;
      }

      // filter out errors from Order Search not found
      if (
        (eventValue?.includes('ENTITY_NOT_FOUND') ||
          eventValue?.includes('Method Not Allowed')) &&
        event.transaction === 'delivery_details'
      ) {
        return null;
      }

      return event;
    }
    return null;
  };
}

export const popsyReportToSentry = (error: Error): void => {
  const shouldReport = SentryUtil.shouldReportToSentry();
  if (shouldReport) {
    if (environment.inServer) {
      console.log(
        JSON.stringify({
          jsonPayload: {
            name: error?.name || undefined,
            message: error?.message || undefined,
            stack: error?.stack || undefined,
          },
          severity: 'ERROR',
          labels: {
            name: error?.name || undefined,
            message: error?.message || undefined,
            stack: error?.stack || undefined,
          },
        })
      );
    } else {
      try {
        captureException(error);

        const message = `${error.message || ''}`.toLowerCase();
        if (message) {
          let shouldIgnore = false;
          for (const ignoreMsg of SENTRY_IGNORE_ERRORS) {
            if (message.includes(ignoreMsg.toLowerCase())) {
              shouldIgnore = true;
              break;
            }
          }

          if (!shouldIgnore) {
            SentryUtil.setMSClarityTag(
              'sentry-error',
              `${error.name || 'Unknown Error'}`
            );
            SentryUtil.setMSClarityTag('sentry-message', `${error.message || '-'}`);
          }
        }
      } catch (sentryError) {
        console.warn('Error reporting to Sentry');
        console.warn(sentryError);
      }
    }
  } else if (environment.debugAnalitycs) {
    SentryUtil.printException(error);
  }
};
